<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="d-flex flex-row-fluid bgi-size-cover bgi-position-center"
      :style="{
        backgroundImage: `url(${backgroundImage})`,
      }"
    >
      <!-- begin:: Content -->
      <div
        class="d-flex flex-row-fluid flex-column justify-content-end align-items-center text-center text-white pb-40"
      >
        <h1 class="display-1 font-weight-bold">OOPS!</h1>
        <span class="display-4 font-weight-boldest mb-4">
          Birşeyler yanlış gitti veya bir hata oluştu.
        </span>
        <span class="display-4 font-weight-boldest mb-4"
          >Lütfen daha sonra tekrar deneyiniz.</span
        >
        <router-link to="/" class="btn btn-primary">Anasayfaya Dön</router-link>
      </div>
      <!-- end:: Content -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'Error',
  mounted() {},
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + 'image/error-bg.jpg';
    },
  },
};
</script>
